@font-face {
  font-family: 'fontello';
  src: url('/assets/fonts/app/fontello.eot?26628340');
  src:
    url('/assets/fonts/app/fontello.eot?26628340#iefix') format('embedded-opentype'),
    url('/assets/fonts/app/fontello.woff2?26628340') format('woff2'),
    url('/assets/fonts/app/fontello.woff?26628340') format('woff'),
    url('/assets/fonts/app/fontello.ttf?26628340') format('truetype'),
    url('/assets/fonts/app/fontello.svg?26628340#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/assets/fonts/app/fontello.svg?26628340#fontello') format('svg');
  }
}
*/

[class^='iconn-']:before,
[class*=' iconn-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-size: 1.375rem;
  color: #dbdbdb;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-circle:before {
  content: '\e800';
}

.icon-messages:before {
  content: '\e801';
}

.icon-help:before {
  content: '\e802';
}

.icon-info_ico:before {
  content: '\e803';
}

.icon-integrations_ico:before {
  content: '\e804';
}

.icon-invoice_ico:before {
  content: '\e805';
}

.icon-notification_ico:before {
  content: '\e806';
}

.icon-projects_ico:before {
  content: '\e807';
}

.icon-question:before {
  content: '\e808';
}

.icon-settings_ico:before {
  content: '\e809';
}

.icon-timetracker_ico:before {
  content: '\e80a';
}

.icon-user_ico:before {
  content: '\e80b';
}

.icon-vacation_ico:before {
  content: '\e80c';
}

.icon-chat_ico:before {
  content: '\e80d';
}

.icon-clients_ico:before {
  content: '\e80e';
}

.icon-employees_ico:before {
  content: '\e80f';
}
