@import 'colors';

.list > .list-wrap {
  .active.list-item-edit {
    .list-item__wrap {
      align-items: baseline;
    }
  }
}

.products-library-modal {
  list {
    overflow: initial;
    .list {
      position: unset;
      width: 100%;
    }
  }
}

.template-library-modal, .products-list {
  list {
    overflow: unset;
  }
  .list {
    position: unset !important;
    width: 100% !important;
  }
}

.estimate-items-list {
  .header-sort__item {
    justify-content: flex-end !important;
    .description-padding {
      padding-right: 0;
    }
  }

  .header-sort__item:first-child {
    .description-padding {
      padding-right: 0;
      padding-left: 0.4rem;
    }
  }

  .header-sort app-resizable:first-child {
    flex: 0 0 32px !important;
  }

  .header-sort app-resizable:nth-of-type(2),
  .header-sort app-resizable:nth-of-type(4) {
    .header-sort__item {
      justify-content: flex-start !important;
      .description-padding {
        padding-left: 0;
        padding-right: 0.4rem;
      }
    }
  }

  .list {
    position: initial !important;
  }
}

.calculations-items-list {
  .header-sort app-resizable:nth-of-type(4) {
    .header-sort__item {
      justify-content: flex-end !important;
      padding-right: 0;
      padding-left: 0.4rem;
    }
  }
  .header-sort app-resizable:nth-of-type(3),
  .header-sort app-resizable:nth-of-type(5) {
    .header-sort__item {
      justify-content: flex-start !important;
      .description-padding {
        padding-left: 0;
        padding-right: 0.4rem;
      }
    }
  }
}

.fixed-actions {
  .list .list-item-edit {
    position: relative;
    min-height: 50px;
    & > div:last-child {
      position: sticky;
      right: 0;
      background: white;
      border-left: 0.0625rem solid $gray-4;
      &:hover {
        background-color: $white-1;
      }
    }
  }

  header-list .header-sort {
    position: relative;
    & > app-resizable:last-child {
      position: sticky;
      right: 0;
      background: white;

      .resizable-draggable {
        border-left: 0.0625rem solid $gray-4;
      }
    }
  }
}
