@import 'colors';

.projects-list {
  .subproject-item {
    background-color: $color-white-bg;

    .list-item-edit {
      .list-item__wrap {
        .project-id--wrap {
          padding-left: 30px;
        }
      }
    }
  }

  .onboarding {
    background-color: $color-green-field-child;
  }

  .onboarding-active {
    color: $color-Svenn-Orange-Red;
  }
}
