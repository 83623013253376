.support-menu-panel {
  position: absolute;
  left: 192px;
  bottom: 120px;

  &.mat-mdc-menu-panel {
    position: fixed;
    top: 0;
    bottom: 0;
    max-width: calc(50vw - 200px); // 200px = side-menu
    height: 100%;
    max-height: 100%;
  }
}

.minimized {
  .support-menu-panel {
    left: 50px;
  }
}
