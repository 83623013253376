.project-overview-tile {
  .list-container {
    min-height: 500px;
  }

  .folders-list {
    &.list-no-items--wrapper {
      border-left: 0;
    }
  }
}
