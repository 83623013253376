@media (max-width: 768px) {
  .cdk-overlay-pane {
    max-width: 100% !important;
  }

  .client-modal {
    width: 100%;
  }
}

@media (orientation: landscape) {
  .cdk-overlay-pane {
    max-width: 100% !important;

    .onboarding-modal {
      max-width: 100vw !important;
    }
  }

  @media (max-width: 1000px) {
    .onboarding-modal .mat-mdc-dialog-container {
      border-radius: 0 !important;
      overflow-x: hidden;
    }
  }
}
