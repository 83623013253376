.reset-mat-panel.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0;
  .mat-expansion-indicator {
    &::after {
      transform: rotate(-45deg);
    }

    &.custom-indicator::after {
      margin-top: 0px;
      transition: all 0.2s;
      margin-left: 6px;
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  &.mat-expanded {
    .mat-expansion-indicator {
      &::after {
        transform: rotate(-135deg) !important;
      }

      &.custom-indicator::after {
        transition: all 0.2s;
        transform: rotate(45deg) !important;
        margin-top: -4px;
      }
    }
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 0;

    .mat-expansion-panel-header-title {
      margin: 4px 12px 4px 0;
      font-family: 'Montserrat Medium', Roboto, 'Helvetica Neue', sans-serif !important;
      font-style: normal;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }

    &:hover {
      background: white !important;
    }
  }

  &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused {
    background-color: white;
  }
}
