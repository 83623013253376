.create-edit-estimate-page {
  .label-text {
    font-size: 12px !important;
  }
  .select-item {
    font-size: 11px !important;
  }
}
.markUp-dropdown {
  .select-item {
    font-size: 11px !important;
  }
}
