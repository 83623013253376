@import 'colors';

.warning-tooltip {
  &.mat-mdc-menu-panel {
    max-width: 340px;
    max-height: 240px;
    overflow-x: hidden;
    min-width: 340px;
  }
}
