@font-face {
  font-family: 'Magnet-Black';
  src: url('/assets/fonts/magnet/Magnet-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-Bold';
  src: url('/assets/fonts/magnet/Magnet-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-BoldItalic';
  src: url('/assets/fonts/magnet/Magnet-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-Italic';
  src: url('/assets/fonts/magnet/Magnet-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-Light';
  src: url('/assets/fonts/magnet/Magnet-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-LightItalic';
  src: url('/assets/fonts/magnet/Magnet-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-Medium';
  src: url('/assets/fonts/magnet/Magnet-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-MediumItalic';
  src: url('/assets/fonts/magnet/Magnet-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Magnet-Regular';
  src: url('/assets/fonts/magnet/Magnet-Regular.woff') format('woff');
  font-display: swap;
}
