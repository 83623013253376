@import 'colors';

.modal {
  max-height: 100vh;
  overflow-y: auto;
}
.modal__header {
  position: relative;
  background-color: #f5f7f870;
  border-bottom: 1px solid $color-black-border;
  padding: 1.25rem;
  &__title {
    color: $color-Svenn-Black;
    font-size: 1.12rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  &__close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
}

.modal__actions {
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 1px solid $color-black-border;
  background-color: #f5f7f870;
}
