@import 'colors';

input {
  height: 2rem;
  color: $text-dark-3;
  font-size: 0.8375rem;
  line-height: 1.375rem;
  padding: 0.625rem 1.0625rem;
  border: 0.0625rem solid $gray-border;
  background-color: $clear-white;
  transition: border-color 0.3s ease;
  width: 100%;
  border-radius: 4px;

  &:focus,
  &.focused {
    background-color: inherit;
    outline: none;
    border: 2px solid $blue-1;
  }

  &::placeholder {
    color: $text-grey-3;
  }

  &.disabled,
  &:disabled {
    color: $disabled-background;
    background: $darker-white;
  }

  &.has-error {
    border: 0.0625rem solid $error-red;
    margin-bottom: 0.5625rem;
  }
}

.validation-error-message {
  text-align: left;
  font-size: 0.625rem;
  color: $error-red;
  padding-top: 4px;
}

textarea {
  color: $text-dark-3;
  font-size: 0.8375rem;
  line-height: 1.375rem;
  padding: 0.625rem 1.0625rem;
  border: 0.0625rem solid $gray-border;
  background-color: $clear-white;
  transition: border-color 0.3s ease;
  width: 100%;
  border-radius: 4px;
  resize: none;

  &:focus {
    background-color: inherit;
    outline: none;
    border: 2px solid $blue-1;
  }

  &::placeholder {
    color: $text-grey-3;
  }

  &.disabled,
  &:disabled {
    color: $disabled-background;
    background: $darker-white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $clear-white;
}

input:-webkit-autofill {
  background-color: $clear-white !important;
}

button {
  background: none;
  border: none;
  box-shadow: none;

  &:hover:enabled {
    cursor: pointer;
  }
}

.form-group {
  padding: 12px 0;
  position: relative;
  &.is-error {
    input {
      border-color: $error-red !important;
      &:focus {
        border-width: 0.0625rem;
      }
    }

    .app-select {
      border-color: $error-red !important;
    }

    textarea {
      border-color: $error-red !important;
    }
    // &:before {       // TODO: set error warning icon
    // color: $error-red;
    // content: '';
    // position: absolute;
    // right: 6px;
    // top:
    // }
    &:after {
      content: attr(data-error);
      display: block;
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: $error-red;
    }
  }
}

.form-label {
  color: $text-dark-3;
  font-size: 12px;
  letter-spacing: 0.12px;
  margin-bottom: 6px;
  display: inline-flex;

  &__required {
    color: $red-1;
  }

  &__create {
    margin-left: auto;
    letter-spacing: 0.11px;
    color: $color-Svenn-Air;

    &:hover {
      cursor: pointer;
    }

    span {
      font-size: 12px;
    }
    .icon {
      font-size: 10px;
      margin-right: 4px;
    }
  }
}
