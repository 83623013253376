.loader-container {
  margin: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--relative {
    position: relative;
  }
}

.first-loader {
  flex: 1;
  @extend .loader-container;
}

.pagination-loader {
  @extend .loader-container;
}
