.account-plan-wrapper {
  app-account-subscriptions {
    app-account-modules-list {
      min-height: 100%;
      .table-list--content {
        overflow: initial;
        list {
          overflow: initial;
        }
        .list {
          position: initial;
          width: 100%;
        }
      }
    }
  }
}
