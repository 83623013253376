.more-action-menu.mat-mdc-menu-panel {
  margin-top: 5px;

  .mat-mdc-menu-content {
    padding: 8px 10px !important;
    border: 1px solid $color-black-border !important;
    border-radius: 4px;
    min-width: 155px;
  }
}
.can-edit-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
  width: 240px !important;
}
