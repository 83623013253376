@import 'colors';

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}

@mixin invalid-input-styling() {
  border-radius: 2px;
  border: solid 2px $color-red-regular;
  color: $color-red-regular;

  &::placeholder {
    color: $color-black-greyscale1;
  }

  &:focus {
    background-color: $color-white;
    border: solid 1px $color-black-greyscale1;

    &::placeholder {
      color: $color-black-greyscale1;
    }
  }
}

@mixin hover {
  &:hover {
    cursor: pointer;
    @content;
  }
}

@mixin active {
  &:active {
    cursor: pointer;
    @content;
  }
}

@mixin focus {
  &:focus {
    cursor: pointer;
    @content;
  }
}

@mixin button-no-select($fontSize: 16px, $weight: 500) {
  user-select: none;
  cursor: pointer;

  @if ($fontSize) {
    font-size: $fontSize;
  }
  @if ($weight) {
    font-weight: $weight;
  } @else {
    font-size: 16px;
    font-weight: 500;
  }
}

@mixin border($height: 1px, $type: solid, $color: $color-black-greyscale1) {
  border: $height $type $color;
  @content;
}

@mixin flex-align {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;

  @content;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;

  @content;
}

@mixin flex-space-around {
  @include flex-wrap;
  justify-content: space-around;

  @content;
}

@mixin flex-space-between {
  @include flex-wrap;
  justify-content: space-between;

  @content;
}

@mixin flex-start {
  @include flex-align;
  justify-content: flex-start;

  @content;
}

@mixin disable-action {
  user-select: none;

  @content;
}

@mixin column-view {
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: row;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    @content;
  }
}

@mixin font-sizing($width, $height: $width, $font-size: $width, $line-height: $width) {
  width: $width;
  height: $height;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin nested-list-styles {
  & ol {
    list-style-type: lower-latin;

    & ol {
      list-style-type: lower-roman;

      & ol {
        list-style-type: upper-latin;

        & ol {
          list-style-type: upper-roman;
        }
      }
    }
  }
}
