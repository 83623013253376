@import 'colors';

// Mobile dark mode styles

$color-dark-mode-back: #241d2d;

body.dark-mode {
  background-color: $color-dark-mode-back;
}

.dark-mode {
  .header-title,
  .mobile-scheduler__header .back-btn .icon,
  .mat-expansion-indicator::after,
  .user-item__title,
  .user-item__title > .text-light,
  .day-item .day-name-label,
  .day-item .day-number-label,
  .period-picker__date-container,
  .dates-container .dates .dates__number,
  .dates-container .dates .dates__name,
  .no-scheduled-label,
  app-sum-hours > div,
  .full-schedule-user-hours-sum {
    color: $color-white !important;
  }

  .dates-container .dates .text-orange,
  .day-item .text-orange .day-name-label,
  .day-item .text-orange .day-number-label {
    color: #f96529 !important;
  }

  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover,
  .mat-expansion-panel {
    background: transparent;
  }

  .tabs .item .link .title {
    color: $color-white;
  }

  .tabs .item.active .link .title {
    color: $color-dark-mode-back;
  }

  .svenn-loader {
    background-color: $color-dark-mode-back;
  }
}
