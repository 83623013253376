.employees-list {
  .small-list {
    .list-wrap {
      min-width: inherit;
    }

    .pagination-details {
      display: none;
    }

    .resizable-draggable {
      min-width: inherit;
      max-width: inherit;

      & > .resize-action {
        visibility: hidden;
      }
    }
  }
}
