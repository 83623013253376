@import 'colors';

.filter-menu,
.reneval-date-menu,
.operand-menu {
  padding: 15px;

  .mat-mdc-menu-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .mat-mdc-radio {
    &-button {
      height: 30px;
      display: contents;

      & .mat-ripple-element {
        background-color: transparent !important;
      }
    }

    &-label-content {
      width: 100%;
    }

    &-inner-circle {
      height: 16px;
      width: 16px;
      left: 2px;
      top: 2px;

      & .mat-mdc-radio-checked {
        border-color: $blue-1 !important;
      }
    }
  }


  .mat-mdc-radio-label-content,
  .mat-mdc-radio-container:nth-child(-n + 2) {
    align-self: flex-start;
  }

  .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-mdc-radio-inner-circle {
    border-color: $blue-1 !important;
  }
}

.mdc-radio__outer-circle {
  border-color: $gray-11 !important;
}

.mdc-radio__inner-circle {
  border-color: $blue-1 !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 !important;
}

.query-filters__filter-btn {
  padding: 10px;
  height: 32px;
  background: $color-white;
  border: 1px solid $gray-border;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  &-remove {
    cursor: pointer;
    font-size: 10px;
    margin-top: 2px;
  }

  &.active {
    background-color: $pipes-6;
  }
}

.query-filters {
  &__query-selection {
    max-width: 20vw;
    display: flex;

    &-done {
      display: flex;
      justify-content: flex-end;
    }
  }
}
