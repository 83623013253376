@import 'colors';

.project-tasks-list {
  .header-sort {
    grid-template-columns: 4% 86% 10%;
  }

  .list-item-edit {
    grid-template-columns: 4% 86% 10%;
  }

  .list-item__item {
    max-width: none !important;
  }

  .create-mode.list-item-edit {
    grid-template-columns: 4% 86% 10%;
  }

  .actions-tile {
    margin-left: 16px;
    margin-right: 16px;
  }

  .employee-list {
    .header-sort {
      grid-template-columns: 100%;
    }

    .list-item-edit {
      grid-template-columns: 100%;
    }
  }
}
