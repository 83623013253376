.str-chat__tooltip {
  display: none !important;
}

.str-chat__message-text-inner.str-chat__message-simple-text-inner > p {
  max-height: 100px !important;
  overflow: auto !important;
}

.str-chat__player-wrapper {
  min-width: 300px;
  min-height: 175px;
}

.str-chat__message-team-form-footer button {
  margin-left: 15px;
}

.react-images__view-wrapper .str-chat__modal-image__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  img.str-chat__modal-image__image {
    max-width: 100%;
    max-height: 100vh;
    width: auto;
  }
}

.str-chat__input--textarea-wrapper {
  flex-direction: column;
  span.str-chat__input-fileupload,
  span.str-chat__small-message-input-fileupload {
    display: flex;
  }
  span.str-chat__input-emojiselect {
    top: calc(100% - 39px);
  }
}

.messaging.str-chat .str-chat__thread {
  margin-left: 10px;
}

.str-chat__edit-message-form-options {
  display: flex;
  align-items: center;
}

.str-chat__small-message-input--textarea-wrapper {
  flex-direction: column;
  .str-chat__small-message-input-emojiselect {
    bottom: 15px;
  }

  .str-chat__small-message-input-fileupload {
    display: flex;
  }
}

.str-chat__modal .str-chat__input-fileupload {
  top: 3px;
}
