.clients-list {
  .table-list--content {
    &.small-list {
      .resizable-draggable {
        & > .resize-action {
          visibility: hidden;
        }
      }

      .list-wrap {
        min-width: inherit;

        .list-item__item {
          padding: 0 5px;
        }
      }

      .pagination-details {
        display: none;
      }
    }
  }
}
