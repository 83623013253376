$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: './fonts/iconmoon-fonts' !default;

$icon-Absence_ico: unquote('"\\e900"');
$icon-Absence: unquote('"\\e901"');
$icon-Address_ico: unquote('"\\e902"');
$icon-Approve_ico: unquote('"\\e903"');
$icon-Arrow_carret_ico: unquote('"\\e904"');
$icon-Arrow_ico: unquote('"\\e905"');
$icon-Arrow_sharp_ico: unquote('"\\e906"');
$icon-Arrow_up_circle_ico: unquote('"\\e907"');
$icon-Arrow_v2_ico: unquote('"\\e908"');
$icon-Attention_ico: unquote('"\\e909"');
$icon-Attention: unquote('"\\e90a"');
$icon-Audio_ico: unquote('"\\e90b"');
$icon-AwardTrophyStar_ico-path1: unquote('"\\e90c"');
$icon-AwardTrophyStar_ico-path2: unquote('"\\e90d"');
$icon-AwardTrophyStar_ico-path3: unquote('"\\e90e"');
$icon-Billable_ico: unquote('"\\e90f"');
$icon-Brick_layering_ico: unquote('"\\e910"');
$icon-Budget_ico: unquote('"\\e911"');
$icon-Budget2_ico: unquote('"\\e912"');
$icon-Bullet_point_ico: unquote('"\\e913"');
$icon-Calculation_ico-path1: unquote('"\\e914"');
$icon-Calculation_ico-path2: unquote('"\\e915"');
$icon-Calculation_ico-path3: unquote('"\\e916"');
$icon-Calculation_ico-path4: unquote('"\\e917"');
$icon-Calculation_ico-path5: unquote('"\\e918"');
$icon-Calculation-initial_icon: unquote('"\\e919"');
$icon-Calculator_focus_ico: unquote('"\\e91a"');
$icon-Calculator_ico: unquote('"\\e91b"');
$icon-Calendar_ico: unquote('"\\e91c"');
$icon-Calendar_new_ico: unquote('"\\e91d"');
$icon-Calendar: unquote('"\\e91e"');
$icon-Calendar1_ico: unquote('"\\e91f"');
$icon-Camera_ico: unquote('"\\e920"');
$icon-Cancel_ico: unquote('"\\e921"');
$icon-Carpentry_ico: unquote('"\\e922"');
$icon-Central_approval_ico: unquote('"\\e923"');
$icon-Checklist_ico: unquote('"\\e924"');
$icon-Checklist_number_ico: unquote('"\\e925"');
$icon-Checklist_text_ico: unquote('"\\e926"');
$icon-Checklist: unquote('"\\e927"');
$icon-ChecklistShield_ico: unquote('"\\e928"');
$icon-Clients_ico: unquote('"\\e929"');
$icon-Clients: unquote('"\\e92a"');
$icon-close_ico: unquote('"\\e92b"');
$icon-Close_round_ico: unquote('"\\e92c"');
$icon-Columns_choose_ico: unquote('"\\e92d"');
$icon-Columns_ico: unquote('"\\e92e"');
$icon-Comment_ico: unquote('"\\e92f"');
$icon-Copy_ico: unquote('"\\e930"');
$icon-Dashboard_ico: unquote('"\\e931"');
$icon-Deadline_ico: unquote('"\\e932"');
$icon-Delete_2_ico: unquote('"\\e933"');
$icon-Delete_ico: unquote('"\\e934"');
$icon-Description_ico: unquote('"\\e935"');
$icon-Deviation_ico: unquote('"\\e936"');
$icon-Deviation: unquote('"\\e937"');
$icon-Document-type_ico: unquote('"\\e938"');
$icon-Documentation_ico: unquote('"\\e939"');
$icon-Download_button_ico: unquote('"\\e93a"');
$icon-Drag_ico: unquote('"\\e93b"');
$icon-Duplicate_ico: unquote('"\\e93c"');
$icon-Edit_ico: unquote('"\\e93d"');
$icon-Edit_new_ico: unquote('"\\e93e"');
$icon-Electrician_ico: unquote('"\\e93f"');
$icon-email_ico: unquote('"\\e940"');
$icon-Email2_ico: unquote('"\\e941"');
$icon-Employee_ico: unquote('"\\e942"');
$icon-Employee: unquote('"\\e943"');
$icon-Error_ico: unquote('"\\e944"');
$icon-Expand_ico: unquote('"\\e945"');
$icon-ExternalLink_ico: unquote('"\\e946"');
$icon-Eye_ico: unquote('"\\e947"');
$icon-Filter_ico: unquote('"\\e948"');
$icon-Filter_new_ico: unquote('"\\e949"');
$icon-Five-zero-zero-one_ico: unquote('"\\e94a"');
$icon-Folder_ico: unquote('"\\e94b"');
$icon-Folder-upload_ico: unquote('"\\e94c"');
$icon-Generate_ico: unquote('"\\e94d"');
$icon-Globe_ico: unquote('"\\e94e"');
$icon-Google_maps_ico: unquote('"\\e94f"');
$icon-Group_arrows_ico: unquote('"\\e950"');
$icon-HideEyeOpti_ico: unquote('"\\e951"');
$icon-Home_ico: unquote('"\\e952"');
$icon-Hours_account_ico: unquote('"\\e953"');
$icon-Image_ico: unquote('"\\e954"');
$icon-Import_ico: unquote('"\\e955"');
$icon-Import_new_ico: unquote('"\\e956"');
$icon-Info_ico: unquote('"\\e957"');
$icon-Info1_ico: unquote('"\\e958"');
$icon-Language_ico: unquote('"\\e959"');
$icon-Language_new_ico: unquote('"\\e95a"');
$icon-Lock_ico: unquote('"\\e95b"');
$icon-Log_out_ico: unquote('"\\e95c"');
$icon-Log_out_new_ico: unquote('"\\e95d"');
$icon-Logo-1: unquote('"\\e95e"');
$icon-Logo-2: unquote('"\\e95f"');
$icon-Logo-3: unquote('"\\e960"');
$icon-Logo-4: unquote('"\\e961"');
$icon-Map_ico: unquote('"\\e962"');
$icon-Marketplace_ico: unquote('"\\e963"');
$icon-Marketplace: unquote('"\\e964"');
$icon-MenuFixed_ico: unquote('"\\e965"');
$icon-Mitt-anbud_ico-path1: unquote('"\\e966"');
$icon-Mitt-anbud_ico-path2: unquote('"\\e967"');
$icon-Mitt-anbud_ico-path3: unquote('"\\e968"');
$icon-More_ico: unquote('"\\e969"');
$icon-More_mobile_-nav_ico: unquote('"\\e96a"');
$icon-Move_ico: unquote('"\\e96b"');
$icon-Navigation_collapse_ico: unquote('"\\e96c"');
$icon-Notification_ico: unquote('"\\e96d"');
$icon-Notification: unquote('"\\e96e"');
$icon-Offers_ico: unquote('"\\e96f"');
$icon-Offers: unquote('"\\e970"');
$icon-Painter_ico: unquote('"\\e971"');
$icon-Phone_ico: unquote('"\\e972"');
$icon-Phone2_ico: unquote('"\\e973"');
$icon-Plumbing_ico: unquote('"\\e974"');
$icon-Plus_2_ico: unquote('"\\e975"');
$icon-Plus_ico: unquote('"\\e976"');
$icon-Plus_light_ico: unquote('"\\e977"');
$icon-Presentation_ico: unquote('"\\e978"');
$icon-Product: unquote('"\\e979"');
$icon-Profile_ico: unquote('"\\e97a"');
$icon-Profile: unquote('"\\e97b"');
$icon-Project_ico: unquote('"\\e97c"');
$icon-Project: unquote('"\\e97d"');
$icon-Qualification_ico: unquote('"\\e97e"');
$icon-Quality_ico: unquote('"\\e97f"');
$icon-Quality: unquote('"\\e980"');
$icon-Question_black_ico: unquote('"\\e981"');
$icon-Question_ico: unquote('"\\e982"');
$icon-Question_new_ico: unquote('"\\e983"');
$icon-Recommend_ico: unquote('"\\e984"');
$icon-Report_ico: unquote('"\\e985"');
$icon-Reset_ico: unquote('"\\e986"');
$icon-Save_ico: unquote('"\\e987"');
$icon-Scheduler_ico: unquote('"\\e988"');
$icon-Scheduler_new_ico: unquote('"\\e989"');
$icon-Search_ico: unquote('"\\e98a"');
$icon-Send_ico: unquote('"\\e98b"');
$icon-Settings_ico: unquote('"\\e98c"');
$icon-Settings: unquote('"\\e98d"');
$icon-Sheet_column_view_ico: unquote('"\\e98e"');
$icon-Sheet_column_view_new_ico: unquote('"\\e98f"');
$icon-SHK-Betriebe_ico: unquote('"\\e990"');
$icon-Shrink_ico: unquote('"\\e991"');
$icon-Sort-_9-1_ico: unquote('"\\e992"');
$icon-Sort-_Z-A_ico: unquote('"\\e993"');
$icon-Sort_1-9_ico: unquote('"\\e994"');
$icon-Sort_A-Z_ico: unquote('"\\e995"');
$icon-Sort_ico: unquote('"\\e996"');
$icon-Sort_new_ico: unquote('"\\e997"');
$icon-Spreadsheet_ico: unquote('"\\e998"');
$icon-Success_ico: unquote('"\\e999"');
$icon-Summary_ico: unquote('"\\e99a"');
$icon-Summary_new_ico: unquote('"\\e99b"');
$icon-Svenn_App_icon_ico-path1: unquote('"\\e99c"');
$icon-Svenn_App_icon_ico-path2: unquote('"\\e99d"');
$icon-Svenn_dark_icon-path1: unquote('"\\e99e"');
$icon-Svenn_dark_icon-path2: unquote('"\\e99f"');
$icon-Svenn_Logo_Dark_ico: unquote('"\\e9a0"');
$icon-Svenn_logo_ico: unquote('"\\e9a1"');
$icon-Svenn_Logo_Light_ico: unquote('"\\e9a2"');
$icon-Svenn-docs_ico: unquote('"\\e9a3"');
$icon-Table_arrow_carret_ico: unquote('"\\e9a4"');
$icon-Team_communication_ico: unquote('"\\e9a5"');
$icon-Team_communication: unquote('"\\e9a6"');
$icon-Template_ico: unquote('"\\e9a7"');
$icon-TextFormat_ico: unquote('"\\e9a8"');
$icon-Tile_view_ico: unquote('"\\e9a9"');
$icon-Time_ico: unquote('"\\e9aa"');
$icon-Time: unquote('"\\e9ab"');
$icon-Timeline_ico: unquote('"\\e9ac"');
$icon-Timeline_view_sheet: unquote('"\\e9ad"');
$icon-Timesheet_ico: unquote('"\\e9ae"');
$icon-Tinsmithing_ico: unquote('"\\e9af"');
$icon-Tischler_ico: unquote('"\\e9b0"');
$icon-Trockenbauerctor_ico: unquote('"\\e9b1"');
$icon-Unapprove_ico: unquote('"\\e9b2"');
$icon-Upgrade_ico: unquote('"\\e9b3"');
$icon-Upload_ico: unquote('"\\e9b4"');
$icon-UploadNew_ico: unquote('"\\e9b5"');
$icon-Video_ico: unquote('"\\e9b6"');
$icon-View_ico: unquote('"\\e9b7"');
$icon-Warning_ico: unquote('"\\e9b8"');
$icon-Warning2_ico: unquote('"\\e9b9"');
$icon-Zimmere_ico: unquote('"\\e9ba"');
$icon-Zip_ico: unquote('"\\e9bb"');

