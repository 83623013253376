@import 'colors';

* {
  font-family: 'Magnet-Regular', 'Montserrat Medium', 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: $text-black;
  min-height: 100%;
  font-size: 0.875rem;
}

html {
  height: 100%;
}

a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a:hover {
  color: $color-info;
}

button {
  &:focus {
    outline: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $b2-dark-brick;
  border-radius: 624.9375rem;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f2;
  border-radius: 6249.9375rem;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;

  &--600 {
    font-weight: 600;
  }
}

.text-smooth {
  -webkit-font-smoothing: antialiased;
}

.text-small {
  font-size: 13px;
}

.text-md {
  font-size: 15px;
}

.text-red {
  color: $red-1;
}

.text-green {
  color: $green-7;
}

.text-dark-brick {
  color: $color-black-light;
}

.text-inactive {
  color: $color-black-placeholder;
}

.text-dark {
  color: $text-dark-3;
}

.text-header {
  font-size: 19px;
  font-weight: bold;
  color: $text-dark-3;
  display: flex;
  align-items: center;
}

.text-weight {
  &--5 {
    font-weight: 500;
  }
}

.fs {
  &-12 {
    font-size: 12px;
  }

  &-16 {
    font-size: 16px;
  }
}
