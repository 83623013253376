@import 'colors';

.checkbox {
  input:checked ~ &__check:after {
    display: block;
  }

  &__label {
    word-break: break-word;
    min-height: 1.2rem;
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    padding-left: 13px;
    margin: 0;
    letter-spacing: -0.031rem /* -0.49/16 */;
    line-height: 1.2;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  &__check {
    position: absolute;
    left: 0;
    width: 13px;
    height: 13px;
    border: 0.0625rem solid rgba(0, 0, 0, 0.53);
    bottom: auto;
    background-color: $clear-white;
    border-radius: 0.1875rem /* 3/16 */;

    &--right {
      @extend .checkbox__check;
      left: unset;
      right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      border: solid $text-black;
      border-width: 0 0.05rem 0.05rem 0;
      left: 3.8px;
      top: 0;
      width: 0.275rem;
      height: 0.5rem;
      transform: rotate(45deg);
      box-sizing: border-box;
    }
  }

  &__description {
    font-size: 0.75rem;
    margin-left: 10px;
  }
}

.checked.checkbox__check:after {
  display: block;
}
