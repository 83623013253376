.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;

  &--align {
    &-center {
      align-items: center;
    }

    &-flex-end {
      align-items: flex-end;
    }
  }

  &--center {
    justify-content: center;
  }
}

@mixin flexrow {
  @extend .flex-row;
  margin: 0 (-1.125rem);
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

// .row-flex > .col basic styles
@mixin flexcol {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  box-sizing: border-box;
}

.row-flex {
  @include flexrow;

  // Use class="col" if you want each item to have a space between columns
  // Otherwise use additional class="mx-0" for a .row-flex for set up margin to 0 and remove minus space over the row
  & > [class*='col'] {
    @include flexcol;

    // Columns modifiers
    &.col--center {
      align-self: center;
    }
    &.col--start {
      align-self: flex-start;
    }
    &.col--end {
      align-self: flex-end;
    }

    @for $i from 1 through 12 {
      &.col-#{$i} {
        flex: $i;
      }
    }
  }

  // content justifying
  &--between {
    justify-content: space-between;
  }
  &--around {
    justify-content: space-around;
  }
  &--center {
    justify-content: center;
  }
  &--end {
    justify-content: flex-end;
  }

  // items alignment
  &--middle {
    align-items: center;
  }
  &--bottom {
    align-items: flex-end;
  }
  &--top {
    align-items: flex-start;
  }
  &--stretch {
    align-items: stretch;
  }
  &--baseline {
    align-items: baseline;
  }

  // flex row modify
  &--nowrap {
    flex-wrap: nowrap;
  }
  &--reverse {
    flex-direction: row-reverse;
  }
  &--column {
    flex-direction: column;
    &.row-flex--reverse {
      flex-direction: column-reverse;
    }
  }

  // Heights
  &--fullheight {
    height: 100%;
  }
}

// replace to another stylwe file
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 8px 8px !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.mx-1 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-2 {
  margin: 16px 16px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.mx-2 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-3 {
  margin: 24px 24px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.mx-3 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-4 {
  margin: 32px 32px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mr-4 {
  margin-right: 32px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.ml-4 {
  margin-left: 32px !important;
}

.mx-4 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-5 {
  margin: 40px 40px !important;
}

.mt-5 {
  margin-top: 40px !important;
}

.mr-5 {
  margin-right: 40px !important;
}

.mb-5 {
  margin-bottom: 40px !important;
}

.ml-5 {
  margin-left: 40px !important;
}

.mx-5 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-6 {
  margin: 48px 48px !important;
}

.mt-6 {
  margin-top: 48px !important;
}

.mr-6 {
  margin-right: 48px !important;
}

.mb-6 {
  margin-bottom: 48px !important;
}

.ml-6 {
  margin-left: 48px !important;
}

.mx-6 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.my-6 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.p-0 {
  padding: 0 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-025 {
  padding-top: 2px !important;
}

.p-1 {
  padding: 8px 8px !important;
}

.pt-1 {
  padding-top: 8px !important;
}

.pr-1 {
  padding-right: 8px !important;
}

.pb-1 {
  padding-bottom: 8px !important;
}

.pl-1 {
  padding-left: 8px !important;
}

.px-1 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-1\.5 {
  padding: 12px 12px !important;
}

.pt-1\.5 {
  padding-top: 12px !important;
}

.pr-1\.5 {
  padding-right: 12px !important;
}

.pb-1\.5 {
  padding-bottom: 12px !important;
}

.pl-1\.5 {
  padding-left: 12px !important;
}

.p-2 {
  padding: 16px 16px !important;
}

.pt-2 {
  padding-top: 16px !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.pb-2 {
  padding-bottom: 16px !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.px-2 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-3 {
  padding: 24px 24px !important;
}

.pt-3 {
  padding-top: 24px !important;
}

.pr-3 {
  padding-right: 24px !important;
}

.pb-3 {
  padding-bottom: 24px !important;
}

.pl-3 {
  padding-left: 24px !important;
}

.px-3 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-4 {
  padding: 32px 32px !important;
}

.pt-4 {
  padding-top: 32px !important;
}

.pr-4 {
  padding-right: 32px !important;
}

.pb-4 {
  padding-bottom: 32px !important;
}

.pl-4 {
  padding-left: 32px !important;
}

.px-4 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-5 {
  padding: 40px 40px !important;
}

.pt-5 {
  padding-top: 40px !important;
}

.pr-5 {
  padding-right: 40px !important;
}

.pb-5 {
  padding-bottom: 40px !important;
}

.pl-5 {
  padding-left: 40px !important;
}

.px-5 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-6 {
  padding: 48px 48px !important;
}

.pt-6 {
  padding-top: 48px !important;
}

.pr-6 {
  padding-right: 48px !important;
}

.pb-6 {
  padding-bottom: 48px !important;
}

.pl-6 {
  padding-left: 48px !important;
}

.px-6 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py-6 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.row-flex > [class*='col'].col-6 {
  flex: 6;
  max-width: 50%;
}
