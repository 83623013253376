.trial-wrapper {
  .mat-expansion-panel {
    box-shadow: none !important;
    background: transparent !important;
    color: $clear-white;

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 !important;
      }
    }

    .mat-expansion-panel-header {
      height: 60px;
      font-weight: bold;
      font-size: 13px;
      padding: 0 10px 0 0;
    }

    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        color: $clear-white;
      }

      .mat-expansion-indicator::after {
        color: $clear-white !important;
      }
    }

    .mat-expansion-panel-header:hover {
      background: unset !important;
    }
  }
}
