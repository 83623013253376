@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ynhyb9');
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ynhyb9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ynhyb9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ynhyb9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ynhyb9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Do not replace icon-Svenn_dark_icon

.icon-Svenn_dark_icon {
  &:before {
    content: $icon-Svenn_dark_icon-path1;
    color: rgb(36, 29, 45);
  }

  &:after {
    content: $icon-Svenn_dark_icon-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

//  Do not replace icon-Svenn_dark_icon

.icon-Absence_ico {
  &:before {
    content: $icon-Absence_ico;
  }
}
.icon-Absence {
  &:before {
    content: $icon-Absence;
  }
}
.icon-Address_ico {
  &:before {
    content: $icon-Address_ico;
  }
}
.icon-Approve_ico {
  &:before {
    content: $icon-Approve_ico;
  }
}
.icon-Arrow_carret_ico {
  &:before {
    content: $icon-Arrow_carret_ico;
  }
}
.icon-Arrow_ico {
  &:before {
    content: $icon-Arrow_ico;
  }
}
.icon-Arrow_sharp_ico {
  &:before {
    content: $icon-Arrow_sharp_ico;
  }
}
.icon-Arrow_up_circle_ico {
  &:before {
    content: $icon-Arrow_up_circle_ico;
  }
}
.icon-Arrow_v2_ico {
  &:before {
    content: $icon-Arrow_v2_ico;
  }
}
.icon-Attention_ico {
  &:before {
    content: $icon-Attention_ico;
  }
}
.icon-Attention {
  &:before {
    content: $icon-Attention;
  }
}
.icon-Audio_ico {
  &:before {
    content: $icon-Audio_ico;
  }
}
.icon-AwardTrophyStar_ico .path1 {
  &:before {
    content: $icon-AwardTrophyStar_ico-path1;
  }
}
.icon-AwardTrophyStar_ico .path2 {
  &:before {
    content: $icon-AwardTrophyStar_ico-path2;
    margin-left: -1em;
  }
}
.icon-AwardTrophyStar_ico .path3 {
  &:before {
    content: $icon-AwardTrophyStar_ico-path3;
    margin-left: -1em;
  }
}
.icon-Billable_ico {
  &:before {
    content: $icon-Billable_ico;
  }
}
.icon-Brick_layering_ico {
  &:before {
    content: $icon-Brick_layering_ico;
  }
}
.icon-Budget_ico {
  &:before {
    content: $icon-Budget_ico;
  }
}
.icon-Budget2_ico {
  &:before {
    content: $icon-Budget2_ico;
  }
}
.icon-Bullet_point_ico {
  &:before {
    content: $icon-Bullet_point_ico;
  }
}
.icon-Calculation_ico .path1 {
  &:before {
    content: $icon-Calculation_ico-path1;
  }
}
.icon-Calculation_ico .path2 {
  &:before {
    content: $icon-Calculation_ico-path2;
    margin-left: -1em;
  }
}
.icon-Calculation_ico .path3 {
  &:before {
    content: $icon-Calculation_ico-path3;
    margin-left: -1em;
  }
}
.icon-Calculation_ico .path4 {
  &:before {
    content: $icon-Calculation_ico-path4;
    margin-left: -1em;
  }
}
.icon-Calculation_ico .path5 {
  &:before {
    content: $icon-Calculation_ico-path5;
    margin-left: -1em;
  }
}
.icon-Calculation-initial_icon {
  &:before {
    content: $icon-Calculation-initial_icon;
  }
}
.icon-Calculator_focus_ico {
  &:before {
    content: $icon-Calculator_focus_ico;
  }
}
.icon-Calculator_ico {
  &:before {
    content: $icon-Calculator_ico;
  }
}
.icon-Calendar_ico {
  &:before {
    content: $icon-Calendar_ico;
  }
}
.icon-Calendar_new_ico {
  &:before {
    content: $icon-Calendar_new_ico;
  }
}
.icon-Calendar {
  &:before {
    content: $icon-Calendar;
  }
}
.icon-Calendar1_ico {
  &:before {
    content: $icon-Calendar1_ico;
  }
}
.icon-Camera_ico {
  &:before {
    content: $icon-Camera_ico;
  }
}
.icon-Cancel_ico {
  &:before {
    content: $icon-Cancel_ico;
  }
}
.icon-Carpentry_ico {
  &:before {
    content: $icon-Carpentry_ico;
  }
}
.icon-Central_approval_ico {
  &:before {
    content: $icon-Central_approval_ico;
  }
}
.icon-Checklist_ico {
  &:before {
    content: $icon-Checklist_ico;
  }
}
.icon-Checklist_number_ico {
  &:before {
    content: $icon-Checklist_number_ico;
  }
}
.icon-Checklist_text_ico {
  &:before {
    content: $icon-Checklist_text_ico;
  }
}
.icon-Checklist {
  &:before {
    content: $icon-Checklist;
  }
}
.icon-ChecklistShield_ico {
  &:before {
    content: $icon-ChecklistShield_ico;
  }
}
.icon-Clients_ico {
  &:before {
    content: $icon-Clients_ico;
  }
}
.icon-Clients {
  &:before {
    content: $icon-Clients;
  }
}
.icon-close_ico {
  &:before {
    content: $icon-close_ico;
  }
}
.icon-Close_round_ico {
  &:before {
    content: $icon-Close_round_ico;
  }
}
.icon-Columns_choose_ico {
  &:before {
    content: $icon-Columns_choose_ico;
  }
}
.icon-Columns_ico {
  &:before {
    content: $icon-Columns_ico;
  }
}
.icon-Comment_ico {
  &:before {
    content: $icon-Comment_ico;
  }
}
.icon-Copy_ico {
  &:before {
    content: $icon-Copy_ico;
  }
}
.icon-Dashboard_ico {
  &:before {
    content: $icon-Dashboard_ico;
  }
}
.icon-Deadline_ico {
  &:before {
    content: $icon-Deadline_ico;
  }
}
.icon-Delete_2_ico {
  &:before {
    content: $icon-Delete_2_ico;
  }
}
.icon-Delete_ico {
  &:before {
    content: $icon-Delete_ico;
  }
}
.icon-Description_ico {
  &:before {
    content: $icon-Description_ico;
  }
}
.icon-Deviation_ico {
  &:before {
    content: $icon-Deviation_ico;
  }
}
.icon-Deviation {
  &:before {
    content: $icon-Deviation;
  }
}
.icon-Document-type_ico {
  &:before {
    content: $icon-Document-type_ico;
  }
}
.icon-Documentation_ico {
  &:before {
    content: $icon-Documentation_ico;
  }
}
.icon-Download_button_ico {
  &:before {
    content: $icon-Download_button_ico;
  }
}
.icon-Drag_ico {
  &:before {
    content: $icon-Drag_ico;
  }
}
.icon-Duplicate_ico {
  &:before {
    content: $icon-Duplicate_ico;
  }
}
.icon-Edit_ico {
  &:before {
    content: $icon-Edit_ico;
  }
}
.icon-Edit_new_ico {
  &:before {
    content: $icon-Edit_new_ico;
  }
}
.icon-Electrician_ico {
  &:before {
    content: $icon-Electrician_ico;
  }
}
.icon-email_ico {
  &:before {
    content: $icon-email_ico;
  }
}
.icon-Email2_ico {
  &:before {
    content: $icon-Email2_ico;
  }
}
.icon-Employee_ico {
  &:before {
    content: $icon-Employee_ico;
  }
}
.icon-Employee {
  &:before {
    content: $icon-Employee;
  }
}
.icon-Error_ico {
  &:before {
    content: $icon-Error_ico;
  }
}
.icon-Expand_ico {
  &:before {
    content: $icon-Expand_ico;
  }
}
.icon-ExternalLink_ico {
  &:before {
    content: $icon-ExternalLink_ico;
  }
}
.icon-Eye_ico {
  &:before {
    content: $icon-Eye_ico;
  }
}
.icon-Filter_ico {
  &:before {
    content: $icon-Filter_ico;
  }
}
.icon-Filter_new_ico {
  &:before {
    content: $icon-Filter_new_ico;
  }
}
.icon-Five-zero-zero-one_ico {
  &:before {
    content: $icon-Five-zero-zero-one_ico;
  }
}
.icon-Folder_ico {
  &:before {
    content: $icon-Folder_ico;
  }
}
.icon-Folder-upload_ico {
  &:before {
    content: $icon-Folder-upload_ico;
  }
}
.icon-Generate_ico {
  &:before {
    content: $icon-Generate_ico;
  }
}
.icon-Globe_ico {
  &:before {
    content: $icon-Globe_ico;
  }
}
.icon-Google_maps_ico {
  &:before {
    content: $icon-Google_maps_ico;
  }
}
.icon-Group_arrows_ico {
  &:before {
    content: $icon-Group_arrows_ico;
  }
}
.icon-HideEyeOpti_ico {
  &:before {
    content: $icon-HideEyeOpti_ico;
  }
}
.icon-Home_ico {
  &:before {
    content: $icon-Home_ico;
  }
}
.icon-Hours_account_ico {
  &:before {
    content: $icon-Hours_account_ico;
  }
}
.icon-Image_ico {
  &:before {
    content: $icon-Image_ico;
  }
}
.icon-Import_ico {
  &:before {
    content: $icon-Import_ico;
  }
}
.icon-Import_new_ico {
  &:before {
    content: $icon-Import_new_ico;
  }
}
.icon-Info_ico {
  &:before {
    content: $icon-Info_ico;
  }
}
.icon-Info1_ico {
  &:before {
    content: $icon-Info1_ico;
  }
}
.icon-Language_ico {
  &:before {
    content: $icon-Language_ico;
  }
}
.icon-Language_new_ico {
  &:before {
    content: $icon-Language_new_ico;
  }
}
.icon-Lock_ico {
  &:before {
    content: $icon-Lock_ico;
  }
}
.icon-Log_out_ico {
  &:before {
    content: $icon-Log_out_ico;
  }
}
.icon-Log_out_new_ico {
  &:before {
    content: $icon-Log_out_new_ico;
  }
}
.icon-Logo-1 {
  &:before {
    content: $icon-Logo-1;
  }
}
.icon-Logo-2 {
  &:before {
    content: $icon-Logo-2;
  }
}
.icon-Logo-3 {
  &:before {
    content: $icon-Logo-3;
  }
}
.icon-Logo-4 {
  &:before {
    content: $icon-Logo-4;
  }
}
.icon-Map_ico {
  &:before {
    content: $icon-Map_ico;
  }
}
.icon-Marketplace_ico {
  &:before {
    content: $icon-Marketplace_ico;
  }
}
.icon-Marketplace {
  &:before {
    content: $icon-Marketplace;
  }
}
.icon-MenuFixed_ico {
  &:before {
    content: $icon-MenuFixed_ico;
  }
}
.icon-Mitt-anbud_ico .path1 {
  &:before {
    content: $icon-Mitt-anbud_ico-path1;
  }
}
.icon-Mitt-anbud_ico .path2 {
  &:before {
    content: $icon-Mitt-anbud_ico-path2;
    margin-left: -1em;
  }
}
.icon-Mitt-anbud_ico .path3 {
  &:before {
    content: $icon-Mitt-anbud_ico-path3;
    margin-left: -1em;
  }
}
.icon-More_ico {
  &:before {
    content: $icon-More_ico;
  }
}
.icon-More_mobile_-nav_ico {
  &:before {
    content: $icon-More_mobile_-nav_ico;
  }
}
.icon-Move_ico {
  &:before {
    content: $icon-Move_ico;
  }
}
.icon-Navigation_collapse_ico {
  &:before {
    content: $icon-Navigation_collapse_ico;
  }
}
.icon-Notification_ico {
  &:before {
    content: $icon-Notification_ico;
  }
}
.icon-Notification {
  &:before {
    content: $icon-Notification;
  }
}
.icon-Offers_ico {
  &:before {
    content: $icon-Offers_ico;
  }
}
.icon-Offers {
  &:before {
    content: $icon-Offers;
  }
}
.icon-Painter_ico {
  &:before {
    content: $icon-Painter_ico;
  }
}
.icon-Phone_ico {
  &:before {
    content: $icon-Phone_ico;
  }
}
.icon-Phone2_ico {
  &:before {
    content: $icon-Phone2_ico;
  }
}
.icon-Plumbing_ico {
  &:before {
    content: $icon-Plumbing_ico;
  }
}
.icon-Plus_2_ico {
  &:before {
    content: $icon-Plus_2_ico;
  }
}
.icon-Plus_ico {
  &:before {
    content: $icon-Plus_ico;
  }
}
.icon-Plus_light_ico {
  &:before {
    content: $icon-Plus_light_ico;
  }
}
.icon-Presentation_ico {
  &:before {
    content: $icon-Presentation_ico;
  }
}
.icon-Product {
  &:before {
    content: $icon-Product;
  }
}
.icon-Profile_ico {
  &:before {
    content: $icon-Profile_ico;
  }
}
.icon-Profile {
  &:before {
    content: $icon-Profile;
  }
}
.icon-Project_ico {
  &:before {
    content: $icon-Project_ico;
  }
}
.icon-Project {
  &:before {
    content: $icon-Project;
  }
}
.icon-Qualification_ico {
  &:before {
    content: $icon-Qualification_ico;
  }
}
.icon-Quality_ico {
  &:before {
    content: $icon-Quality_ico;
  }
}
.icon-Quality {
  &:before {
    content: $icon-Quality;
  }
}
.icon-Question_black_ico {
  &:before {
    content: $icon-Question_black_ico;
  }
}
.icon-Question_ico {
  &:before {
    content: $icon-Question_ico;
  }
}
.icon-Question_new_ico {
  &:before {
    content: $icon-Question_new_ico;
  }
}
.icon-Recommend_ico {
  &:before {
    content: $icon-Recommend_ico;
  }
}
.icon-Report_ico {
  &:before {
    content: $icon-Report_ico;
  }
}
.icon-Reset_ico {
  &:before {
    content: $icon-Reset_ico;
  }
}
.icon-Save_ico {
  &:before {
    content: $icon-Save_ico;
  }
}
.icon-Scheduler_ico {
  &:before {
    content: $icon-Scheduler_ico;
  }
}
.icon-Scheduler_new_ico {
  &:before {
    content: $icon-Scheduler_new_ico;
  }
}
.icon-Search_ico {
  &:before {
    content: $icon-Search_ico;
  }
}
.icon-Send_ico {
  &:before {
    content: $icon-Send_ico;
  }
}
.icon-Settings_ico {
  &:before {
    content: $icon-Settings_ico;
  }
}
.icon-Settings {
  &:before {
    content: $icon-Settings;
  }
}
.icon-Sheet_column_view_ico {
  &:before {
    content: $icon-Sheet_column_view_ico;
  }
}
.icon-Sheet_column_view_new_ico {
  &:before {
    content: $icon-Sheet_column_view_new_ico;
  }
}
.icon-SHK-Betriebe_ico {
  &:before {
    content: $icon-SHK-Betriebe_ico;
  }
}
.icon-Shrink_ico {
  &:before {
    content: $icon-Shrink_ico;
  }
}
.icon-Sort-_9-1_ico {
  &:before {
    content: $icon-Sort-_9-1_ico;
  }
}
.icon-Sort-_Z-A_ico {
  &:before {
    content: $icon-Sort-_Z-A_ico;
  }
}
.icon-Sort_1-9_ico {
  &:before {
    content: $icon-Sort_1-9_ico;
  }
}
.icon-Sort_A-Z_ico {
  &:before {
    content: $icon-Sort_A-Z_ico;
  }
}
.icon-Sort_ico {
  &:before {
    content: $icon-Sort_ico;
  }
}
.icon-Sort_new_ico {
  &:before {
    content: $icon-Sort_new_ico;
  }
}
.icon-Spreadsheet_ico {
  &:before {
    content: $icon-Spreadsheet_ico;
  }
}
.icon-Success_ico {
  &:before {
    content: $icon-Success_ico;
  }
}
.icon-Summary_ico {
  &:before {
    content: $icon-Summary_ico;
  }
}
.icon-Summary_new_ico {
  &:before {
    content: $icon-Summary_new_ico;
  }
}
.icon-Svenn_App_icon_ico .path1 {
  &:before {
    content: $icon-Svenn_App_icon_ico-path1;
    color: rgb(249, 101, 41);
  }
}
.icon-Svenn_App_icon_ico .path2 {
  &:before {
    content: $icon-Svenn_App_icon_ico-path2;
    color: rgb(255, 255, 255);
    margin-left: -1em;
  }
}
.icon-Svenn_dark_icon .path1 {
  &:before {
    content: $icon-Svenn_dark_icon-path1;
  }
}
.icon-Svenn_dark_icon .path2 {
  &:before {
    content: $icon-Svenn_dark_icon-path2;
    margin-left: -1em;
  }
}
.icon-Svenn_Logo_Dark_ico {
  &:before {
    content: $icon-Svenn_Logo_Dark_ico;
  }
}
.icon-Svenn_logo_ico {
  &:before {
    content: $icon-Svenn_logo_ico;
  }
}
.icon-Svenn_Logo_Light_ico {
  &:before {
    content: $icon-Svenn_Logo_Light_ico;
  }
}
.icon-Svenn-docs_ico {
  &:before {
    content: $icon-Svenn-docs_ico;
  }
}
.icon-Table_arrow_carret_ico {
  &:before {
    content: $icon-Table_arrow_carret_ico;
  }
}
.icon-Team_communication_ico {
  &:before {
    content: $icon-Team_communication_ico;
  }
}
.icon-Team_communication {
  &:before {
    content: $icon-Team_communication;
  }
}
.icon-Template_ico {
  &:before {
    content: $icon-Template_ico;
  }
}
.icon-TextFormat_ico {
  &:before {
    content: $icon-TextFormat_ico;
  }
}
.icon-Tile_view_ico {
  &:before {
    content: $icon-Tile_view_ico;
  }
}
.icon-Time_ico {
  &:before {
    content: $icon-Time_ico;
  }
}
.icon-Time {
  &:before {
    content: $icon-Time;
  }
}
.icon-Timeline_ico {
  &:before {
    content: $icon-Timeline_ico;
  }
}
.icon-Timeline_view_sheet {
  &:before {
    content: $icon-Timeline_view_sheet;
  }
}
.icon-Timesheet_ico {
  &:before {
    content: $icon-Timesheet_ico;
  }
}
.icon-Tinsmithing_ico {
  &:before {
    content: $icon-Tinsmithing_ico;
  }
}
.icon-Tischler_ico {
  &:before {
    content: $icon-Tischler_ico;
  }
}
.icon-Trockenbauerctor_ico {
  &:before {
    content: $icon-Trockenbauerctor_ico;
  }
}
.icon-Unapprove_ico {
  &:before {
    content: $icon-Unapprove_ico;
  }
}
.icon-Upgrade_ico {
  &:before {
    content: $icon-Upgrade_ico;
  }
}
.icon-Upload_ico {
  &:before {
    content: $icon-Upload_ico;
  }
}
.icon-UploadNew_ico {
  &:before {
    content: $icon-UploadNew_ico;
  }
}
.icon-Video_ico {
  &:before {
    content: $icon-Video_ico;
  }
}
.icon-View_ico {
  &:before {
    content: $icon-View_ico;
  }
}
.icon-Warning_ico {
  &:before {
    content: $icon-Warning_ico;
  }
}
.icon-Warning2_ico {
  &:before {
    content: $icon-Warning2_ico;
  }
}
.icon-Zimmere_ico {
  &:before {
    content: $icon-Zimmere_ico;
  }
}
.icon-Zip_ico {
  &:before {
    content: $icon-Zip_ico;
  }
}

