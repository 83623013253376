@import 'colors';
projects-filters,
scheduler-filters {
  margin-top: -1px;
}

.scheduler-planner,
.mobile-scheduler {
  &__wrapper {
    .filter-wrap {
      .filter-wrap-tile {
        border-top: none !important;
      }
    }
    .item {
      // background-color: $color-white !important;
      .link {
        color: $color-Svenn-Black !important;
        padding: 10px 0 !important;
      }
      .title {
        font-weight: 500 !important;
      }

      &.active,
      &.active-route {
        background-color: $pipes-6 !important;
        .title {
          transform: scale(1.1);
        }
      }
    }
  }
}
