@import 'colors';

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  &__item {
    & + & {
      margin-left: 1.125rem;
    }
  }
  &__link {
    color: $color-black-tint;
    font-size: 13px;
    line-height: 17px;
    border-bottom: 2px;
    border-color: transparent;
    padding-bottom: 2px;
    font-weight: 500;
    &:hover {
      color: $color-Svenn-Black;
    }
    &.is-active {
      color: $b2-dark-brick;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 9px;
      font-family: Magnet-Bold, Magnet, sans-serif;
    }
  }
}
