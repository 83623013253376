@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import 'theme/mixins.scss';
@import 'theme/fonts';
@import 'theme/typography';
@import 'theme/colors';
@import 'theme/flex';
@import 'theme/buttons';
@import 'theme/form';
@import 'theme/checkbox.scss';
@import 'theme/iconmoon-icons.scss';
@import 'theme/sidebar-icons.scss';
@import 'theme/loader.scss';
@import 'theme/modal.scss';
@import 'theme/tabs.scss';
@import 'theme/expand-panel.scss';

@import 'theme/chat.min.scss';
@import 'theme/_chat.scss';
@import 'theme/query-filter';
@import 'theme/helpers';

@import '@angular/cdk/overlay-prebuilt.css';

.mat-mdc-menu-panel {
  max-width: 400px !important;
  background: #fff;
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 5px 8px 0 rgb(0 0 0 / 14%),
    0 1px 14px 0 rgb(0 0 0 / 12%);
}

html {
  --mat-menu-item-label-text-font:initial;
  --mat-menu-item-label-text-size: initial;
  --mat-menu-item-label-text-tracking: initial;
  --mat-menu-item-label-text-line-height: initial;
  --mat-menu-item-label-text-weight: initial;
  --mdc-dialog-container-color: transparent;

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: initial ;
    --mdc-radio-disabled-unselected-icon-color: initial ;
    --mdc-radio-unselected-hover-icon-color: initial ;
    --mdc-radio-unselected-icon-color: initial ;
    --mdc-radio-unselected-pressed-icon-color: initial ;
    --mdc-radio-selected-focus-icon-color: initial ;
    --mdc-radio-selected-hover-icon-color: initial ;
    --mdc-radio-selected-icon-color: initial ;
    --mdc-radio-selected-pressed-icon-color: initial ;
    --mat-radio-ripple-color: initial ;
    --mat-radio-checked-ripple-color: initial ;
    --mat-radio-disabled-label-color: initial ;
    --mat-radio-label-text-color: initial ;
  }

  .mat-mdc-dialog-surface {
    box-shadow: none !important;
    transform: inherit !important;
    transition: inherit !important;
  }

  .mat-mdc-autocomplete-panel {
    max-height: 100% !important;
  }
}

// TODO rewrite material 18 dropdown and menu-item
.mat-mdc-menu-content {
  padding: 0 !important;
  -webkit-font-smoothing: initial !important;
}

.mat-mdc-menu-item {
  min-height: initial !important;
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  line-height: inherit !important;
}

// TODO end

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2e6f7a;
  --mdc-linear-progress-track-color: $gray-border !important;
}

.intl-tel-input {
  width: 100%;
}

.-border-bottom-1 {
  margin-bottom: -0.0625rem;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.75);
}

i.icon.icon-Arrow_v2_ico {
  position: relative;
  transform: rotate(180deg);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Magnet-Regular', 'Montserrat Medium', Roboto, 'Helvetica Neue', sans-serif;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.wrap-resizable-flexible-table {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-end;
}

.has-error {
  .app-select,
  .app-datepicker__input {
    border-color: #e35205 !important;
  }
}

.error {
  font-size: 12px;
  line-height: 16px;
  color: $error-red;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  min-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: inline-block;

  &.small:after {
    width: 16px;
    height: 16px;
    border: 1px solid $white;
    border-color: #6991ac transparent #6991ac transparent;
    animation: loader 1.2s linear infinite;
  }

  &:after {
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid $white;
    border-color: #6991ac transparent #6991ac transparent;
    animation: loader 1.2s linear infinite;
  }
}

.skeleton-loader {
  border-radius: 2px;
  padding: 0 4px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading-skeleton 1.5s infinite ease-in-out;
}

@keyframes loading-skeleton {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.list-no-items--wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: -0.0625rem;
  border: 0.0625rem solid $gray-4;
  position: relative;
  background-color: $color-white;
}

.table-list {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 15px;
    position: relative;
    overflow: hidden;
    background-color: $color-white-bg;
  }
}

.xng-breadcrumb-item {
  font-size: 14px;
  .xng-breadcrumb-link {
    font-weight: 300;
    color: $color-Svenn-Black !important;
    text-decoration: underline;
  }
  .xng-breadcrumb-link-disabled {
    text-decoration: none;
  }
}

.sub-title {
  color: $color-Svenn-Black;
  font-size: 16px;
  font-weight: normal;
}

.xng-breadcrumb-separator {
  transform: rotate(180deg);
  font-size: 9px;
  color: $color-Svenn-Black;
  margin-top: 4px;
}

.editable-list {
  .list-item-edit:not(.active) {
    position: relative;
    background-color: rgba(161, 161, 161, 0.15);
    pointer-events: none;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.list {
  .list-item-edit {
    .action-controls {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      position: relative;
      .edit-loader {
        position: absolute;
        top: auto;
        left: -13px;
      }
    }
  }
}

.separator {
  border-left: 1px solid $gray-border;
  border-right: none;
  height: 20px;
}

.clickable {
  cursor: pointer;
  color: $text-dark-3;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.page-link {
  background-color: white;
}

.page-item.disabled .page-link {
  background-color: white;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.reset-list {
  ul,
  ol {
    li {
      list-style: inherit;
    }
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

p {
  margin-bottom: 0;
}

.action-icon {
  font-size: 15px;
  color: $color-black-light;
}

.modal-tile {
  text-align: center;
  &__wrap {
    padding: 65px 22px;
    .icon-wrap {
      margin-bottom: 15px;
    }

    .actions-wrap {
      display: flex;
      justify-content: center;
      padding: 0 10px;
      .btn {
        margin-left: 10px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__content {
    margin-bottom: 24px;
    .title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 16px;
      line-height: 26px;
      color: $color-Svenn-Black;
    }

    .description {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $color-black-light;
    }
  }
}

.no-set {
  &__value {
    color: $gray-4;
  }
}

hr {
  border: none;
  height: 1px;
  color: $color-black-border; /* old IE */
  background-color: $color-black-border; /* Modern Browsers */
}

.images-slider-modal {
  max-width: unset !important;
  width: 100%;
  height: 100%;
  .mat-mdc-dialog-container {
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: transparent;
    .mat-dialog-content {
      max-height: unset !important;
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.svenn-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 auto;
  height: 100vh;
  animation: heartbeat 2.3s infinite;

  &--text {
    width: 80px;
  }

  &--logo {
    width: 45px;
    margin-right: 20px;
  }
}

// Rewrite matmenu styles

.no-mat-menu-styles {
  &.mat-mdc-menu-panel {
    min-height: initial;
    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.download-mobile-app-menu {
    position: relative;
    bottom: 10px;
    right: 10px;
  }
}

.menu-panel {
  &.multiselect-menu {
    background: transparent;
    box-shadow: none;
    max-width: 300px;
  }
}

.mat-tooltip {
  font-size: 13px;
  max-width: 250px !important;
}

.material-name-tooltip {
  max-width: 100% !important;
}

.translated {
  border-bottom: 1px dashed !important;
  background: #cbe6fb66 !important;
}

.action-icon-btn {
  min-height: 30px;
}

.mat-calendar {
  overflow: auto;
  height: 100%;
}

.footer-wrapper {
  flex: 1;
  width: calc(100% - 200px);
}

.main-features-wrapper {
  overflow: auto;
  display: inline-flex;
  transition: width 0.2s ease-in-out;
  height: 100%;

  &.is-trial {
    height: calc(100% - 40px);

    .edit-employee-wrapper {
      max-height: calc(100% - 56px - 15px - 72px - 85px - 45px);
    }
  }
}

view-activation-footer {
  width: calc(100% - 191px);
}

.minimized {
  .upgrade-plan-btn > button {
    min-width: initial !important;
    .icon {
      margin: 0;
    }
  }

  .view-activation-footer {
    width: calc(100% - 56px);
  }
}

.is-mobile {
  overflow: hidden;
}

// TODO delete after scheduler release

.scheduler-subscription-modal {
  .modal-tile__wrap {
    .icon-wrap {
      img {
        width: 40px;
      }
    }

    .title {
      max-width: 240px;
      margin: 0 auto;
    }
  }
}

.heartbeat-icon {
  .icon {
    animation: heartbeat 1.3s infinite;
  }
}

// Media styles for modules
@import 'theme/media/index';

// Deep styles for modules
@import 'theme/deep-styles/index';

// Todo delete after Beamer fix two iframes
iframe#beamerUtilities {
  display: none;
}

.ck-editor__editable_inline {
  * {
    all: revert;
  }

  ol {
    ol {
      list-style-type: lower-latin !important;
      ol {
        list-style-type: lower-roman !important;
        ol {
          list-style-type: upper-latin !important;
          ol {
            list-style-type: upper-roman !important;
          }
        }
      }
    }
  }
}
.list .list-item {
  color: $text-black;
  font-size: 0.75rem;
  list-style: none;

  &:hover {
    cursor: pointer;
    background-color: $white-1;
  }
}

.hidden {
  display: none !important;
}

#hubspot-conversations-inline-iframe,
#hubspot-conversations-inline-parent,
iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.hubspot-chat {
  .close-hubspot-chat {
    display: block;
  }
}

.hbs-chat {
  #hubspot-messages-iframe-container {
    display: none !important;
  }

  .close-hubspot-chat {
    display: none;
  }
}

.onboarding-welcome-window {
  max-width: inherit !important;

  .mat-mdc-dialog-container {
    padding: 0;
    border-radius: 20px;
  }
}

.mat-mdc-dialog-container,
.mat-mdc-menu-panel {
  background: $color-white;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}

.intro {
  * {
    all: revert;
  }

  strong {
    font-family: 'Magnet-Bold', 'Magnet', sans-serif !important;
    font-weight: 900;
  }

  ol {
    @include nested-list-styles;
  }
}

.mat-tooltip {
  background: #4d4d4d;
  padding: 7px 15px;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 11px;
}

.mat-tooltip-text__pre-line {
  white-space: pre-line;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

.edit-trans-btn {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  cursor: pointer;
  color: #e35205;
  background-color: white;
  padding: 0 2px 0 3px;
  box-shadow:
    0 3px 5px -1px rgb(0 0 0 / 20%),
    0 5px 8px 0 rgb(0 0 0 / 14%),
    0 1px 14px 0 rgb(0 0 0 / 12%);
  border-radius: 3px;
  z-index: 9999999;
}

.translated:hover {
  position: relative;
  .edit-trans-btn {
    display: block;
  }
}

// todo move to separate file

.drag-placeholder {
  background: #fafafa;
  border: dotted 3px $color-Svenn-Sunset;
  min-height: 42px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

body.menu-minimized {
  main-sidebar-tile {
    .sidebar {
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.5), 0 10px 10px -5px rgba(0, 0, 0, 0.3);
      transition: width 0.3s ease;
    }

    max-width: 56px;
    z-index: 999;
  }
}

body.menu-minimized.minimized {
  .sidebar {
    width: 56px;
  }

  main-sidebar-tile {
    z-index: 0;
  }
}

body.menu-minimized .sidebar:hover {
  width: 250px;
}

body.minimized {
  .sidebar {
    .sidebar__toggle {
      visibility: hidden;
    }

    .sidebar__toggle .flex-container {
      &:hover {
        .sidebar__toggle--tooltip {
          &.open {
            display: block !important;
          }

          &.closed {
            display: none !important;
          }
        }
      }
    }

    .sidebar__logo .logo-wrap > i {
      display: none;
    }

    .icon-Arrow_v2_ico {
      transform: rotate(0);
    }

    .toggle-container {
      display: none;
    }

    group .title,
    .sidebar__link-description {
      width: 0;
    }

    .sidebar__logo {
      margin: 0;

      img {
        margin-right: 0;
      }
    }

    .sidebar__link {
      position: initial;

      .sidebar__link-label-wrapper {
        display: none;
      }

      .sidebar__link-logo {
        text-align: center;
        display: flex;
        justify-content: center;
      }

      &--collapse {
        .sidebar__link-logo {
          visibility: inherit;
          display: flex;
          justify-content: center;
        }
      }

      &:hover {
        .sidebar__link-description {
          display: flex;
        }
      }
    }

    .profile-avatar-tile {
      .name {
        display: none;
      }
    }

    .settings.notification-btn {
      display: none;
    }

    .sidebar__upgrade-btn {
      .btn > span {
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }
    }
  }
}
.sidebar__upgrade-btn {
  .btn {
    height: 35.5px;
    position: relative;
  }
  .btn > span {
    text-wrap: nowrap;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
  .btn > i {
    position: relative;
    z-index: 1;
  }
}

.word-break-keep {
  white-space: normal !important;
  word-break: keep-all !important;
  overflow-wrap: anywhere !important;
}

// TODO: VADYM Calculations;

.editable-input, .editable-select {
  text-align: right;
  background: transparent;
  border: none;
  border-radius: 0;
  color: $highlight-blue;
  cursor: pointer;
  margin-top: -1px;
  height: 100%;
  padding: 0 0 2px;

  &:focus, .focus {
    border: none;
    border-bottom: 1px solid black !important;
    color: black;
  }
}

.editable-markup {
  .form-control {
    padding: 0;
    border: none;
    background: transparent;
  }

  .app-select {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    &:focus {
      border: none !important;
    }
  }
}


// TODO: VADYM Calculations;

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
}
