@import 'colors';

.question-mark {
  position: relative;
  top: 4px;
  background: url('/assets/quals/question_ico.svg');
  width: 14px;
  height: 14px;
  margin-left: 9px;
}

.cursor-pointer {
  cursor: pointer;
}

.border {
  &--saw {
    border: 1px solid $color-saw;
  }
  &--none {
    border: none !important;
  }
}

.border-gray {
  border: 0.0625rem solid $gray-border;
}
