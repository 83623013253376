@import 'colors';

.select-deviations-side-panel {
  .select-checkbox {
    .checkbox {
      input:checked ~ .checkbox__check {
        background: $b2-dark-brick;
      }

      input:checked ~ .checkbox__check:after {
        border: solid $color-white;
        border-width: 0 0.09rem 0.09rem 0;
      }
    }
  }
}

.deviation-details-menu-wrapper {
  simple-card {
    .card {
      max-width: 250px;
      min-width: 250px;
    }
  }
}

.deviations-side-panel-actions-wrapper {
  btn-action {
    .btn-action {
      .btn-icon {
        font-size: 14px;
      }
    }
  }
}

.checklist-deviation-image-gallery-wrapper {
  .form-wrap__images-container {
    .loader-wrap {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}
