.actions-wrap.estimation-action-wrapper {
  .action-item {
    color: $color-Svenn-Black !important;
    &.delete-option {
      color: $color-Svenn-Black !important;
      &:hover {
        color: $color-Svenn-Sunset !important;
      }
    }
    &:hover {
      &.edit-option,
      &.duplicate-option {
        color: $color-Svenn-Sunset !important;
      }
    }
  }
}
