.planner-wrapper {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .filter-wrap {
    .filter-wrap-tile {
      justify-content: flex-end;
      background-color: $color-white;
    }
  }

  .select-view {
    width: 112px;
    max-width: 112px;

    button {
      border: none;

      .label-title {
        font-weight: 700;
      }

      .label-text {
        color: $color-Svenn-Air;
        font-weight: 100;
      }
    }
  }
}

.menu-panel.absence-info {
  min-height: 100px !important;
  min-width: 200px;
  max-width: 400px;
  min-height: auto;
  overflow: hidden;

  .mat-mdc-menu-content {
    background: $clear-white;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 8px !important;
  }
}
