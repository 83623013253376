.settings-deviation-general {
  deviation-incidents-list,
  deviation-type-list,
  deviation-tags-list {
    .list-item {
      &__item {
        min-width: 100%;
      }
    }
  }
}
