@import 'colors';

$btn-border: #c14808;
$color-Svenn-Sunset-Light: #ffd3bc;
$color-Svenn-Sunset-Ripple: #e87132;
$color-Svenn-Sunset-Disabled: #e3520570;
$color-Svenn-Sunset-Solid: #ffb994;
$color-Svenn-Sunset-Solid-Disabled: #e3520566;

button {
  &.btn {
    min-width: 7.25rem;
    position: relative;
    border-radius: 4px;
    padding: 8px 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-return {
      display: flex;
      color: $clear-white;
      background-color: $b2-dark-brick;
      border-radius: 5px;
      margin-right: 8px;
      font-size: 20px;
      padding: 7px;
    }

    &-white {
      background-color: $clear-white;
    }

    &__solid {
      background-color: $color-Svenn-Sunset;
      border: 1px solid $btn-border;
      color: $clear-white;
      font-size: 0.8125rem;
      font-weight: 600;
      .icon-plus {
        color: $color-Svenn-Sunset-Solid;
      }
      .icon-spinner {
        display: none;
        color: $clear-white;
      }
      &:hover {
        background-color: $btn-border;
      }
      &:focus {
        box-shadow: 0 0 0 2px $gray-6;
      }
      &:disabled {
        border-color: $color-Svenn-Sunset-Solid-Disabled;
        background-color: $color-Svenn-Sunset-Solid-Disabled;
        .btn-icon {
          color: $clear-white;
        }
      }

      &--green-field {
        background-color: $color-green-field-child;
        border: none;
        color: $b2-dark-brick;

        &:hover {
          background-color: $color-green-field-child;
        }

        .btn-icon {
          color: $b2-dark-brick;
        }
      }

      &__black {
        background-color: $color-Svenn-Black;
        border-color: $color-Svenn-Black;
        &:hover {
          background-color: $color-Svenn-Black;
        }
      }
      &__download {
        background-color: $b2-dark-brick;
        border-color: $text-black;
        color: $clear-white;
        .btn-icon {
          color: $clear-white;
        }
        &:hover {
          background-color: $text-black;
        }
        &:disabled {
          border-color: $text-black;
          background-color: $b2-dark-brick;
          opacity: 0.5;
        }
        &__inner-btn {
          font-size: 11px;
        }
      }
    }
    &__outline {
      background-color: $color-white;
      border: 1px solid $btn-border;
      color: $color-Svenn-Sunset;
      font-size: 0.8125rem;
      font-weight: 600;
      .icon-plus {
        color: $color-Svenn-Sunset;
      }
      .icon-spinner {
        display: none;
        color: $color-Svenn-Sunset;
      }
      &:hover {
        background-color: $color-Svenn-Sunset-Light;
        color: $color-white;
      }
      &:focus {
        background-color: $color-Svenn-Sunset-Light;
        border: 1px solid $color-Svenn-Sunset-Light;
      }
      &:disabled {
        border-color: $color-Svenn-Sunset-Disabled;
        color: $color-Svenn-Sunset-Disabled;
        background-color: $clear-white;
      }
    }
    &__plus {
      padding-left: 2rem;
      .icon.icon-plus {
        display: block;
        left: 0;
        top: 0.6rem;
        font-size: 0.8rem;
        margin-left: 0.8rem;
        position: absolute;
      }
    }
    &__spinner {
      .icon.icon-spinner {
        display: block;
        left: 0.7rem;
        top: 0.72rem;
        font-size: 0.8rem;
        width: 12px;
        height: 12px;
        position: absolute;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &:hover {
        background-color: $btn-border;
      }
      &:focus {
        box-shadow: 0 0 0 2px $gray-6;
      }
    }
    &__ripple-solid {
      background-position: center;
      transition: background 0.8s;
      &:hover {
        background: $color-Svenn-Sunset-Ripple radial-gradient(circle, transparent 1%, $color-Svenn-Sunset-Ripple 1%)
          center/15000%;
      }
      &:active {
        background-color: $color-Svenn-Sunset-Ripple;
        background-size: 100%;
        transition: background 0s;
      }
    }
    &__ripple-outline {
      background-position: center;
      transition: background 0.8s;
      &:hover {
        background: $color-Svenn-Sunset-Light radial-gradient(circle, transparent 1%, $color-Svenn-Sunset-Light 1%) center/15000%;
      }
      &:active {
        background-color: $color-Svenn-Sunset-Ripple;
        background-size: 100%;
        transition: background 0s;
      }
    }
    &__primary {
      background: $color-Svenn-Sunset;
      color: white;
      font-size: 0.8125rem;

      &--black {
        background: $color-Svenn-Black;
        &:hover {
          background-color: $color-Svenn-Black;
        }
        &:focus {
          background-color: $color-Svenn-Black;
        }
      }

      &:disabled {
        opacity: 0.5;
      }
      // add hover, disabled and other styles
    }
    &__bordered {
      color: $color-black-light;
      background-color: $white;
      border: 1px solid $color-black-light;
      font-size: 0.8125rem;
      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        outline: 1px solid $blue-1;
      }
      // add hover, disabled and other styles
    }
    &__bordered-light {
      color: $color-Svenn-Air;
      border: 1px solid $color-black-greyscale2;
      &:disabled {
        opacity: 0.5;
      }
      // add hover, disabled and other styles
    }
    &__empty-danger {
      color: $color-red-regular;
      border: none;
      font-size: 12px;
      font-weight: 550;
      &:disabled {
        opacity: 0.5;
      }
      // add hover, disabled and other styles
    }
    &__stroke {
      color: $color-Svenn-Sunset;
      border: 1px solid $color-Svenn-Sunset;
      font-size: 0.8125rem;
      &:disabled {
        opacity: 0.5;
      }
      // add hover, disabled and other styles
    }
    &__flat {
      color: $color-Svenn-Sunset;
      border: none;
      font-size: 0.8125rem;
      font-weight: 600;
      &:disabled {
        opacity: 0.5;
      }
      &:hover {
        transform: scale(1.1);
      }
      &.no-transform {
        transform: none;
      }
    }
    &__light {
      color: $color-black-light;
      border: none;
      font-size: 0.8125rem;
      font-weight: 600;
      &:disabled {
        opacity: 0.5;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    &__filter {
      color: $color-Svenn-Black;
      border: none;
      font-size: 16px;
      font-weight: 600;
      &:disabled {
        opacity: 0.5;
      }
      &:focus {
        box-shadow: 0 0 0 2px $gray-6;
      }
    }
    &__sm {
      min-width: 4rem;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.btn-action {
  position: relative;
  letter-spacing: 0.12px;
  color: $color-Svenn-Sunset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  min-width: 86px;
  font-weight: bold;
  border: 1px solid $gray-border;
  border-radius: 4px;

  &:disabled {
    opacity: 0.6;
  }

  &.borderless {
    border: none;
  }

  &.delete {
    color: $color-red-dark;
  }

  &.edit {
    color: $color-Svenn-Air;
  }

  &.black {
    color: $b2-dark-brick;
  }

  &.black-light {
    color: $color-black-light;
  }

  &.txt-black {
    color: $text-black;
  }

  &.txt-dark-brick {
    color: $b2-dark-brick;
  }

  &.txt-red {
    color: $bloody-marry;
  }

  &.large {
    padding: 8px 20px;
  }

  &.medium {
    padding: 6px 10px;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.option-icon {
  font-size: 15px;
  display: flex;
  color: grey;
  &-save {
    color: $green-2;
    font-size: 12px;
  }

  &-cancel {
    color: $color-red-regular;
    font-size: 14px;
  }
}

.no-transform {
  transform: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
