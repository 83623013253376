.select-plan-modal {
  max-height: calc(100vh - 10px);
  height: 100%;

  .mat-mdc-dialog-container {
    overflow: unset;
    padding: 0;
    border-radius: 20px;
  }
}
