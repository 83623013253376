.cdk-overlay-container .cdk-overlay-pane {
  .profile-menu-panel {
    position: absolute !important;
    left: 184px;
    top: -210px;
  }
}

.minimized {
  .cdk-overlay-container .cdk-overlay-pane {
    .profile-menu-panel {
      position: absolute !important;
      left: 60px;
      top: -210px;
    }
  }
}

.minimized {
  .sidebar {
    .full-search-btn {
      display: none;
    }

    .small-search-btn {
      display: flex !important;
    }
  }
}
