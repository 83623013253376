@import 'colors';

.tracking-overview-wrapper {
  .mat-expansion-panel {
    box-shadow: none !important;

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0 !important;
      }
    }
  }

  .filter-wrap {
    .filter-wrap-tile {
      background-color: $color-white;
    }
  }

  .filter-summary-wrap {
    border-bottom: 0;
    .filter-wrap-tile {
      position: relative;
      justify-content: normal;
      background-color: $color-white;
    }
  }

  reports-list {
    min-height: 250px;
  }

  .select-view {
    width: 112px;
    max-width: 112px;

    button {
      border: none;

      .label-title {
        font-weight: 700;
      }

      .label-text {
        color: $color-Svenn-Air;
        font-weight: 100;
      }
    }
  }

  .billed {
    background-color: $color-green-field-child;
  }

  .billable {
    background-color: $color-sweet-nail;
  }
}

.actions-tile {
  .delete-action {
    color: $bloody-marry;

    &:hover {
      color: $color-Svenn-Orange-Red;
      .icon-wrap {
        .icon {
          color: $color-Svenn-Orange-Red;
        }
      }
    }
  }
}

.mat-expansion-panel {
  border-radius: 0 !important;
}
