@import 'colors';

.wages-table {
  .create-mode {
    cursor: default;
  }

  .actions-tile {
    margin-left: 16px;
    margin-right: 16px;
  }
}
