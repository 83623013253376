@import 'colors';

.project-deviations {
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .filter-wrap {
    .filter-wrap-tile {
      margin-top: -1px;
      margin-bottom: -1px;
      padding: 10px 10px;
      background-color: $color-white;
    }
  }
}
