@import 'colors';

.document-file-tile {
  .actions {
    actions button {
      position: relative;
      transform: rotate(90deg);
    }
  }
}
