@import 'colors';

.hours-account-details {
  .filter-summary-wrap {
    border-left: 0;
    border-bottom: 0;
    .filter-wrap-tile {
      position: relative;
      background-color: $color-white;
      justify-content: normal;
    }
  }
}
