@import 'colors';

.sign-up-wrapper {
  height: 100%;
  .sign-up-checkbox {
    .app-checkbox {
      .app-checkbox__check {
        background: $color-white;
      }
    }
  }

  header .header-wrapper {
    position: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
  }
}

@media (max-width: 870px) {
  .sign-up-wrapper header .header-wrapper {
    justify-content: center;
  }
}

.step-one-btn-wrapper-no {
  // no - norwegian
  btn-loader {
    button {
      padding: 13px 30px !important;
      font-size: 15px !important;
    }
  }
}
